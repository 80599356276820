import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

import "@fontsource/varela-round";
import '../styles/global.css'

export default function Layout({ children }) {
    return (
        <div className="wrapper">
            <header>
                <Navbar />
            </header>
            <main className="content">
                {children}
            </main>
            <Footer />
        </div>
    )
}