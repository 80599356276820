import { Link } from 'gatsby'
import React from 'react'
import { mainContent, github, logo } from '../styles/nav.module.css'

export default function Navbar() {
    return (
        <nav>
            <Link className={logo} to="/">Grinply</Link>

            <section className={mainContent}>
                {/* <Link to="/">Home</Link>
                <Link to="/about">About us</Link>
                <Link to="/login">Blog</Link> */}
            </section>

            <section>
                <a className={github} href="https://github.com/grinply" aria-label="Grinply on GitHub" rel="noopener noreferrer" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 16">
                        <path d="M8.184.25C3.822.25.254 3.817.254 8.18c0 3.469 2.28 6.442 5.452 7.533.396.099.495-.198.495-.397V13.93c-2.18.495-2.676-.992-2.676-.992-.396-.892-.892-1.19-.892-1.19-.694-.495.099-.495.099-.495.793.1 1.19.793 1.19.793.693 1.289 1.883.892 2.28.694.099-.496.297-.892.495-1.09-1.784-.199-3.568-.892-3.568-3.965 0-.892.297-1.586.793-2.082-.1-.198-.397-.991.099-2.082 0 0 .694-.198 2.18.793a6.391 6.391 0 0 1 1.983-.297c.694 0 1.388.1 1.982.297 1.487-.99 2.181-.793 2.181-.793.397 1.09.198 1.884.1 2.082.495.595.792 1.289.792 2.082 0 3.072-1.883 3.667-3.667 3.865.297.397.594.893.594 1.587v2.18c0 .198.1.496.595.397 3.172-1.09 5.452-4.064 5.452-7.534-.1-4.361-3.668-7.93-8.03-7.93z" />
                    </svg>
                </a>
            </section>
        </nav>
    )
}