import { Link } from 'gatsby'
import React from 'react'
import * as styles from '../styles/footer.module.css'

export default function Footer() {
    return (
        <footer>
            <section className={styles.ending} itemtype="https://schema.org/Corporation">
                ©2021 <span itemtype="legalName">Grinply</span>
                
                <span className={styles.address} itemprop="address" itemscope itemtype="http://schema.org/PostalAddress">
                    <span itemprop="addressLocality">Rio de Janeiro</span>, 
                    <span className={styles.country} itemprop="addressCountry">Brazil</span>
                </span>
            </section>
        </footer>
    )
}