import React from 'react'
import Layout from '../components/Layout'

export default function about() {
    return (
        <Layout>
            <div>
                Page not Found.
            </div>
        </Layout>
    )
}
